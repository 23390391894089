import { 
  getPrizeDrawWinnerById as _getPrizeDrawWinnerById, 
  getPrizeDrawWinnersRef 
} from '../repositories/prize-draw-winners-repository';
import { getUserById } from '../repositories/users-repository';
import { map, assign } from "lodash";

/**
 * @function getPrizeDrawWinnerById
 * @param {String} id
 */
export const getPrizeDrawWinnerById = async id => {
  const { user: { id: userId }, ...rest } = await _getPrizeDrawWinnerById(id);
  const user = await getUserById(userId);
  return assign({}, { ...rest }, { user });
};

/**
 * @function getPrizeDrawWinnersForMonthAndYear
 * @param {Number} monthNumber Non-zero indexed month (Jan = 1, Feb = 2, etc...)
 * @param {Number} year 
 */
export const getPrizeDrawWinnersForMonthAndYear = async (monthNumber, year) => {
  const snapshot = await getPrizeDrawWinnersRef()
    .where('monthNumber', '==', monthNumber)
    .where('year', '==', year)
    .orderBy('prize', 'asc')
    .get();
  let prizeDrawWinnerIds = [];
  snapshot.forEach(({ id }) => prizeDrawWinnerIds.push(id));
  const prizeDrawWinners = await Promise.all(
    map(prizeDrawWinnerIds, async prizeDrawWinnerId => {
      const prizeDrawWinner = await getPrizeDrawWinnerById(prizeDrawWinnerId);
      return prizeDrawWinner;
    })
  );
  return prizeDrawWinners;
};