import React from "react";
import video from "../assets/video/promo.mp4";
import poster from "../assets/images/promo.jpg";
import Video from "./video";
import "./how-it-works-video.scss";

export default () => (
  <div className="section section--how-it-works-video">
    <h2 className="section__heading heading--white">
      How does it work?
    </h2>
    <h3 className="section__subheading subheading--grey">
      Watch the video to find out more.
    </h3>
    <Video 
      video={video}
      poster={poster}
      modifier="how-it-works-video"
    />
  </div>
);