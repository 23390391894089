import firebase from "gatsby-plugin-firebase";
import FirebaseCollectionTypes from "../constants/firebase-collection-types";

export const getPrizeDrawWinnersRef = () => (
  firebase
    .firestore()
    .collection(FirebaseCollectionTypes.PRIZE_DRAW_WINNERS)
);

/**
 * @function getPrizeDrawWinnerById
 * @param {String} id
 */
export const getPrizeDrawWinnerById = async id => {
  const snapshot = await getPrizeDrawWinnersRef().doc(id).get();
  return { id, ...snapshot.data() };
};