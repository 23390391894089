import firebase from "gatsby-plugin-firebase";
import FirebaseCollectionTypes from "../constants/firebase-collection-types";

export const getUsersRef = () => (
  firebase
    .firestore()
    .collection(FirebaseCollectionTypes.USERS)
);

/**
 * @function getUserById
 * @param {String} id
 */
export const getUserById = async id => {
  const snapshot = await getUsersRef().doc(id).get();
  return { id, ...snapshot.data() };
};