import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "./get-codes-cta.scss";

export default () => (
  <div className="section section--get-codes-cta">
    <div className="section__inner section__inner--get-codes-cta">
      <h2 className="section__heading heading--white">
        Are you an escape game owner?
      </h2>
      <p className="section__paragraph paragraph--light-grey">
        Click the link below if you own an escape game and you want to get
        hold of your QR codes so that players can check in and check out of
        your games, and so that you can confirm players previously played
        games.
        <br />
        <br />
        Please ensure that you request your QR codes from an email that is
        associated with your escape room so we can verify you are the owner.
        Also, please tell us the names of the rooms you want QR codes for.
      </p>
      <OutboundLink
        className="section__cta section__cta--get-codes-cta section__cta--inverted-grey"
        href="mailto:info@escapetheapp.com?subject=Give me my escape QR codes!&amp;body=I own the following escape rooms and can confirm that this email address is associated to these games."
        eventAction="get_qr_codes_button_click">
        Give me my codes!
      </OutboundLink>
    </div>
  </div>
);