import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { get, isNil } from "lodash";
import Layout from "../components/layout";
import LandingHeader from "./landing-header";
import HowItWorksVideo from "./how-it-works-video";
import PrizeDraw from "./prize-draw";
import KickstarterCta, { 
  KickstarterCtaBlack, 
  KickstarterCtaGrey,
  KickstarterCtaBlueGreen,
  KickstarterRegisterEmailCtaBlack,
  KickstarterRegisterEmailCtaBlueGreen,
  KickstarterBackUsNowCtaBlueGreen,
  KickstarterHowItWorksCtaBlack,
  KickstarterBackUsHowItWorksCtaBlack
} from "./kickstarter-cta";
import RegisterForNewsletter from './register-for-newsletter';
import GetCodesCta from "./get-codes-cta";
import { initFacebookPixel, trackPageView } from '../utilities/facebook-pixel';
import "./landing-page.scss";

export const queryData = graphql`
  query($uid: String) {
    prismic {
      allLanding_headers(uid: $uid) {
        edges {
          node {
            page_title
            page_sub_title
            page_header_cta_type
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1280, srcSetBreakpoints: [1280], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_image_mobile
            main_image_mobileSharp {
              childImageSharp {
                fluid(maxWidth: 640, srcSetBreakpoints: [320, 640], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_background_image
            main_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1800, srcSetBreakpoints: [1800], quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            seo_image
            section_1
            section_2
            section_3
            section_4
            section_5
            facebook_pixel_code_init_id
          }
        }
      }
    }
  }
`;

const SectionTypes = {
  HOW_IT_WORKS_VIDEO: 'how-it-works-video',
  PRIZE_DRAW: 'prize-draw',
  GET_CODES_CTA: 'get-codes-cta',
  KICKSTARTER_CTA: 'kickstarter-cta',
  KICKSTARTER_CTA_BLACK: 'kickstarter-cta-black',
  KICKSTARTER_CTA_GREY: 'kickstarter-cta-grey',
  KICKSTARTER_CTA_BLUE_GREEN: 'kickstarter-cta-blue-green',
  KICKSTARTER_REGISTER_EMAIL_CTA_BLACK: 'kickstarter-register-email-cta-black',
  KICKSTARTER_REGISTER_EMAIL_CTA_BLUE_GREEN: 'kickstarter-register-email-cta-blue-green',
  KICKSTARTER_BACK_US_NOW_CTA_BLUE_GREEN: 'kickstarter-back-us-now-cta-blue-green',
  KICKSTARTER_HOW_IT_WORKS_CTA_BLACK: 'kickstarter-how-it-works-cta-black',
  KICKSTARTER_BACK_US_HOW_IT_WORKS_CTA_BLACK: 'kickstarter-back-us-how-it-works-cta-black',
  REGISTER_FOR_NEWSLETTER: 'register-for-newsletter'
};

const SectionComponentTypes = {
  [SectionTypes.HOW_IT_WORKS_VIDEO]: HowItWorksVideo,
  [SectionTypes.PRIZE_DRAW]: PrizeDraw,
  [SectionTypes.GET_CODES_CTA]: GetCodesCta,
  [SectionTypes.KICKSTARTER_CTA]: KickstarterCta,
  [SectionTypes.KICKSTARTER_CTA_BLACK]: KickstarterCtaBlack,
  [SectionTypes.KICKSTARTER_CTA_GREY]: KickstarterCtaGrey,
  [SectionTypes.KICKSTARTER_CTA_BLUE_GREEN]: KickstarterCtaBlueGreen,
  [SectionTypes.KICKSTARTER_REGISTER_EMAIL_CTA_BLACK]: KickstarterRegisterEmailCtaBlack,
  [SectionTypes.KICKSTARTER_REGISTER_EMAIL_CTA_BLUE_GREEN]: KickstarterRegisterEmailCtaBlueGreen,
  [SectionTypes.KICKSTARTER_BACK_US_NOW_CTA_BLUE_GREEN]: KickstarterBackUsNowCtaBlueGreen,
  [SectionTypes.KICKSTARTER_HOW_IT_WORKS_CTA_BLACK]: KickstarterHowItWorksCtaBlack,
  [SectionTypes.KICKSTARTER_BACK_US_HOW_IT_WORKS_CTA_BLACK]: KickstarterBackUsHowItWorksCtaBlack,
  [SectionTypes.REGISTER_FOR_NEWSLETTER]: RegisterForNewsletter
};

const _renderSection = (data, sectionType) => {
  const content = get(data, sectionType, null);
  if (isNil(content)) return null;
  const contentType = get(content, ["0", "text"]);
  const Component = get(SectionComponentTypes, contentType, undefined);
  return !isNil(Component) ? (
    <Component {...data} />
  ) : null;
};

const LandingPage = props => {
  const edges = get(props, ["data", "prismic", "allLanding_headers", "edges"], []);
  const context = get(props, ["pageContext", "uid"], "index");
  const data = get(edges, ["0", "node"], undefined);
  const initFacebookPixelAndTrackPageView = () => {
    const initId = get(data, ["facebook_pixel_code_init_id", "0", "text"], undefined);
    initFacebookPixel(initId);
    trackPageView();
  };
  const onMounted = () => initFacebookPixelAndTrackPageView();
  useEffect(onMounted, []);
  return (
    <Layout>
      <LandingHeader data={data} context={context} />
      {_renderSection(data, 'section_1')}
      {_renderSection(data, 'section_2')}
      {_renderSection(data, 'section_3')}
      {_renderSection(data, 'section_4')}
      {_renderSection(data, 'section_5')}
    </Layout>
  );
};

export default LandingPage;
