import React, { useState, useEffect } from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { 
  getPrizeDrawWinnersForMonthAndYear 
} from '../firebase/data-providers/prize-draw-winners-data-provider';
import { map, get, isNil } from 'lodash';
import dayjs from 'dayjs';
import AppStoreLogo from "../assets/svg/app-store-logo.svg";
import PlayStoreLogo from "../assets/svg/play-store-logo.svg";
import FourFreeTicket from "../assets/svg/four-free-ticket.svg";
import TwoFreeTicket from "../assets/svg/two-free-ticket.svg";
import OneFreeTicket from "../assets/svg/one-free-ticket.svg";
import "./prize-draw.scss";

const LAST_DRAW_DATE = dayjs();
const LAST_DRAW_DATE_SCORING_PERIOD = dayjs().month(-1);
const THIS_YEAR = LAST_DRAW_DATE.get('year');
const THIS_MONTH = LAST_DRAW_DATE.get('month') + 1;
const PRIZES_DICTIONARY = {
  oneFreeTicket: {
    icon: OneFreeTicket
  },
  twoFreeTicket: {
    icon: TwoFreeTicket
  },
  fourFreeTicket: {
    icon: FourFreeTicket
  }
};

const PrizeDraw = () => {
  const [prizeDrawWinners, setPrizeDrawWinners] = useState(null);

  const _setPrizeDrawWinners = async () => {
    // TODO: Once we've got the relevant rebuild hooks wired up to rebuild the site upon 
    // Prismic and Firebase changes we should move this logic into the `gatsby-node.js` 
    // file to get the data and render statically into the site rather than getting the 
    // data at runtime. 
    const prizeDrawWinners = await getPrizeDrawWinnersForMonthAndYear(
      THIS_MONTH, THIS_YEAR
    );
    setPrizeDrawWinners(prizeDrawWinners);
  };

  const _renderPrizeIcon = prizeId => {
    const Component = get(PRIZES_DICTIONARY, [prizeId, 'icon']);
    return (isNil(Component)) ? null : <Component />;
  };

  const _renderPrizeWithOrdinal = prize => {
    switch (prize) {
      case 1: return '1st Prize';
      case 2: return '2nd Prize';
      case 3: return '3rd Prize';
      default: return '';
    }
  };

  useEffect(() => {
    _setPrizeDrawWinners();
  }, []);

  return (
    <div className="section section--prize-draw">
      <div className="section__inner section__inner--prize-draw">
        <h2 className="section__heading section__heading--prize-draw heading--white">
          Earn points and win prizes!
        </h2>
        <h3 className="section__subheading section__subheading--prize-draw subheading--grey">
          On the first day of every month we give away escape game prizes to
          three of our top scoring players from the previous month.
        </h3>
        <div className="covid-19-message">
          <h2 className="covid-19-message__subheading subheading--black">
            COVID-19 update
          </h2>
          <p className="covid-19-message__paragraph paragraph--black">
            Escape game tickets won in our monthly draws have no expiry date
            and can be claimed at any time in the future. Prizes can be won
            without having to play games by adding previously played games or
            recruiting other players.
          </p>
        </div>
        <div className="prizes">
          <div className="prizes__prize">
            <FourFreeTicket className="prizes__prize-image" />
            <h3 className="prizes__prize-subheading subheading--white">
              1st prize
            </h3>
            <p className="prizes__prize-paragraph paragraph--light-grey">
              Four free tickets to an escape game of your choice costing up to $120.
            </p>
          </div>
          <div className="prizes__prize">
            <TwoFreeTicket className="prizes__prize-image" />
            <h3 className="prizes__prize-subheading subheading--white">
              2nd prize
            </h3>
            <p className="prizes__prize-paragraph paragraph--light-grey">
              Two free tickets to an escape game of your choice costing up to $60.
            </p>
          </div>
          <div className="prizes__prize">
            <OneFreeTicket className="prizes__prize-image" />
            <h3 className="prizes__prize-subheading subheading--white">
              3rd prize
            </h3>
            <p className="prizes__prize-paragraph paragraph--light-grey">
              One free ticket to an escape game of your choice costing up to $30.
            </p>
          </div>
        </div>
        <div className="prize-draw-winners">
          <h2 className="section__heading section__heading--prize-draw heading--white">
            Congratulations to {LAST_DRAW_DATE.format('MMMM')}'s winners!
          </h2>
          <ul className="prize-draw-winners__list">
            {map(prizeDrawWinners, ({ prizeId, prize, user: { codeName, realName }, scoreIncrease }, index) => (
              <li className="prize-draw-winners__list-item" key={index}>
                <div className="prize-draw-winners__list-item-icon">
                  {_renderPrizeIcon(prizeId)}
                </div>
                <p className="prize-draw-winners__list-item-name-and-score">
                  <span className="prize-draw-winners__list-item-name">{realName} ({codeName})</span>
                  <br />
                  Scored {scoreIncrease} points in {LAST_DRAW_DATE_SCORING_PERIOD.format('MMMM')}
                </p>
                <span className="prize-draw-winners__list-item-prize">
                  {_renderPrizeWithOrdinal(prize)}
                </span>
              </li>
            ))}
          </ul>
          <p className="prize-draw-winners__prize-collection-prompt paragraph--light-grey">
            * To claim your prizes login to Escape the App and head over to the prizes screen.
          </p>
        </div>
        <h3 className="section__subheading section__subheading--prize-draw">
          Download the app for your chance to win prizes. 
          <br/>
          <br/>
          It's free and easy to score points so what are you waiting for?! 
        </h3>
        <div className="store-logos store-logos--no-margin-bottom">
          <OutboundLink
            href="https://apps.apple.com/us/app/escape/id1460848804"
            target="_blank"
            rel="noreferrer"
            eventLabel="ios"
            eventCategory="download_app"
            eventAction="download_app_button_click">
            <AppStoreLogo className="store-logos__logo store-logos__logo--app-store" />
          </OutboundLink>
          <PlayStoreLogo className="store-logos__logo store-logos__logo--play-store" />
        </div>
      </div>
    </div>
  );
};

export default PrizeDraw;