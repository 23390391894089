import React from "react";
import PropTypes from "prop-types";
import { assign, isNil } from 'lodash';
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { get } from "lodash";
import { RichText } from "prismic-reactjs";
import Img from "gatsby-image";
import SEO from "../components/seo";
import AppStoreLogo from "../assets/svg/app-store-logo.svg";
import PlayStoreLogo from "../assets/svg/play-store-logo.svg";

const PageHeaderCtaTypes = {
  DOWNLOAD_APP: 'download-app'
};

const DownloadAppCta = () => (
  <div className="store-logos">
    <OutboundLink
      href="https://apps.apple.com/us/app/escape/id1460848804"
      target="_blank"
      rel="noreferrer"
      eventLabel="ios"
      eventCategory="download_app"
      eventAction="download_app_button_click">
      <AppStoreLogo className="store-logos__logo store-logos__logo--app-store" />
    </OutboundLink>
    <PlayStoreLogo className="store-logos__logo store-logos__logo--play-store" />
  </div>
);

const _renderCta = ctaType => {
  switch (ctaType) {
    case PageHeaderCtaTypes.DOWNLOAD_APP: {
      return <DownloadAppCta />;
    }
    default: return null;
  }
};

const _renderSeo = data => {
  let seoProps = {
    title: RichText.asText(data.page_title)
  };
  const seoImage = get(data, ['seo_image', 'url'], undefined);
  if (!isNil(seoImage)) {
    seoProps = assign(seoProps, { image: seoImage });
  }
  return (
    <SEO {...seoProps} />
  );
};

const LandingHeader = ({ data, context }) => {
  if (!data) return null;
  const mainImage = get(data, ["main_imageSharp", "childImageSharp", "fluid"], undefined);
  const mainImageMobile = get(data, ["main_image_mobileSharp", "childImageSharp", "fluid"], undefined);
  const mainBackgroundImage = get(data, ["main_background_imageSharp", "childImageSharp", "fluid"], undefined);
  const ctaType = get(data, ["page_header_cta_type"], undefined);
  return (
    <>
      {_renderSeo(data)}
      <div className={`section section--hero section--${context}`}>
        {mainBackgroundImage ? (
          <div className={`landing-page__hero-background-image-wrapper landing-page__hero-background-image-wrapper--${context}`}>
            <Img
              className={`landing-page__hero-background-image landing-page__hero-background-image--${context}`}
              fluid={mainBackgroundImage}
            />
          </div>
        ) : null}
        {mainImage ? (
          <div className={`landing-page__hero-image-wrapper landing-page__hero-image-wrapper--desktop landing-page__hero-image-wrapper--${context}`}>
            <Img
              className={`landing-page__hero-image landing-page__hero-image--desktop landing-page__hero-image--${context}`}
              fluid={mainImage}
            />
          </div>
        ) : null}
        {mainImageMobile ? (
          <div className={`landing-page__hero-image-wrapper landing-page__hero-image-wrapper--mobile landing-page__hero-image-wrapper--${context}`}>
            <Img
              className={`landing-page__hero-image landing-page__hero-image--mobile landing-page__hero-image--${context}`}
              fluid={mainImageMobile}
            />
          </div>
        ) : null}
        <div className={`section__inner section__inner--hero section__inner--${context}`}>
          <h2 className={`section__heading section__heading--hero section__heading--${context}`}>
            {RichText.asText(data.page_title)}
          </h2>
          <h3 className={`section__subheading section__subheading--hero section__subheading--${context}`}>
            {RichText.asText(data.page_sub_title)}
          </h3>
          {_renderCta(ctaType)}
        </div>
      </div>
    </>
  );
};

LandingHeader.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired
};

export default LandingHeader;
